import React, { useState, useEffect, useRef } from 'react';
import './PerObjetivos.scss';
import { ClientImages, PowerBi } from "../../../../api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const clientImagesController = new ClientImages();
const powerBiController = new PowerBi();

const codigosValidos = ["OBJ_1", "OBJ_2", "OBJ_3", "OBJ_4", "OBJ_5", "OBJ_6"];
const textosObjetivos = {
  OBJ_1: "Lograr la formación integral en cada ciclo de vida a lo largo de la vida de las personas fortaleciendo sus capacidades agencia para el desarrollo de sus proyectos de vida y los proyectos de bien común con un sentido de ciudadanía plena, a través de una educación de calidad, moderna, equitativa, accesible y en valores ciudadanos, a fin de responder a las demandas y desafíos de la región y el país.",
  OBJ_2: "Fortalecer el desarrollo y aplicación de la ciencia, tecnología e innovación en los diversos espacios educativos para asegurar la transformación social, económica e institucional que posicione a La Libertad en el tercio superior de competitividad nacional.",
  OBJ_3: "Fomentar una ciudadanía responsable, respetuosa, tolerante, solidaria, inclusiva y participativa que contribuye a una sociedad democrática con cohesión social e identidad regional, que contribuya en la construcción de la gobernabilidad y gobernanza democrática en el marco del Estado de Derecho y democrático.",
  OBJ_4: "Fortalecer los espacios de las familias y las comunidades como entornos socioeducativos que garantizan condiciones de educabilidad en la convivencia familiar y comunitaria, siendo entornos protectores de los derechos, ricos en oportunidades y medios de aprendizajes, así como facilitadores del ejercicio de ciudadanía y la convivencia intergeneracional y democrática, libre de todo tipo de discriminación y de toda forma de violencia.",
  OBJ_5: "Promover una educación equitativa e inclusiva en todos los ciclos de vida a lo largo de la vida de las personas, según etapas, formas, modalidades educativas, asegurando las condiciones de asequibilidad, accesibilidad, adaptabilidad, aceptabilidad y transitabilidad educativa para su desarrollo y participación en la vida comunitaria, política y económica, social, cultural y tecnológica",
  OBJ_6: "Fortalecer el compromiso y la participación corresponsable de los actores regionales públicos y privados involucrados en la educación y el desarrollo personal y social en cada ciclo de vida a la largo de la vida de las personas, siendo capaces de trabajar de manera colaborativa, articulada y concertada para el logro de los objetivos comunes de la educación y el desarrollo regional."
};

const indexLIN1 = codigosValidos.indexOf("OBJ_1");
const initialSlideIndex = indexLIN1 !== -1 ? indexLIN1 : 0;

export function PerObjetivos() {
  const [objetivos, setObjetivos] = useState([]);
  const [objetivoSeleccionado, setObjetivoSeleccionado] = useState(null);
  const sliderRef = useRef();

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        // Carga acumulativa de los datos de ClientImages y PowerBi
        let todosLosDatosImagenes = await cargarTodosLosDatos(clientImagesController.getClientImages.bind(clientImagesController));
        let todosLosDatosPowerBi = await cargarTodosLosDatos(powerBiController.getPowerBi.bind(powerBiController));

        // Filtrado y mapeo usando codigosValidos
        const prioridadesData = codigosValidos.flatMap(codigo => {
          const datoPowerBi = todosLosDatosPowerBi.find(dato => dato.code === codigo);
          const datoImagen = todosLosDatosImagenes.find(dato => dato.code === codigo);
          
          if (datoPowerBi && datoImagen) {
            return [{
              id: codigo,
              icono: datoPowerBi.miniature,
              texto: textosObjetivos[codigo],
              imagen: datoImagen.miniature,
              powerBi: datoPowerBi.url
            }];
          }
          return [];
        });

        setObjetivos(prioridadesData);
      } catch (error) {
        console.error("Error cargando datos:", error);
      }
    };

    const cargarTodosLosDatos = async (funcionCargar) => {
      let todosLosDatos = [];
      let paginaActual = 1;
      let hayMasPaginas = true;

      while (hayMasPaginas) {
        const respuesta = await funcionCargar({ page: paginaActual });
        todosLosDatos = todosLosDatos.concat(respuesta.docs);

        if (respuesta.hasNextPage) {
          paginaActual += 1;
        } else {
          hayMasPaginas = false;
        }
      }

      return todosLosDatos;

    }

    if(window.innerWidth < 700) { // Asume que 700 es tu breakpoint para móviles
      sliderRef.current.slickGoTo(initialSlideIndex);
    }

    cargarDatos();
  }, [objetivos]);


  const handleChangeObjetivo = (codigo) => {
    setObjetivoSeleccionado(objetivos.find(objetivo => objetivo.id === codigo));
  };

  const PrevArrow = ({ onClick }) => (
    <button className="slick-prev" onClick={onClick} aria-label="Previous">
      {"<"} 
    </button>
  );
  
  const NextArrow = ({ onClick }) => (
    <button className="slick-next" onClick={onClick} aria-label="Next">
      {">"} 
    </button>
  );

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: initialSlideIndex,
    responsive: [
      {
        breakpoint: 1429,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          initialSlide: initialSlideIndex,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          initialSlide: initialSlideIndex,
        }
      }
    ]
  };

  return (
    <div className="perObjetivos">
      <h2>Objetivos estratégicos del PER La Libertad</h2>
      <div className="slider-container">
        <Slider ref={sliderRef} {...sliderSettings}>
          {objetivos.map(objetivo => (
            <div key={objetivo.id} onClick={() => handleChangeObjetivo(objetivo.id)}>
              <img src={objetivo.icono} alt={`Objetivo ${objetivo.id}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className={`objetivosDetalle ${objetivoSeleccionado ? "activo" : ""}`}>
        <div className="objetivosTexto">{objetivoSeleccionado?.texto}</div>
        <div className="objetivosImagen">
          <img src={objetivoSeleccionado?.imagen} alt={`Detalle del objetivo ${objetivoSeleccionado?.id}`} />
        </div>
      </div>
      <div className={`objetivosPowerBi ${objetivoSeleccionado ? "activo" : ""}`}>
        {objetivoSeleccionado && (
          <iframe
            src={objetivoSeleccionado.powerBi}
            frameBorder="0"
            allowFullScreen={true}
            width="100%"
            title={`Objetivo ${objetivoSeleccionado.id} - PowerBi`}
          ></iframe>
        )}
      </div>
    </div>
  );
}
