import React, { useState, useEffect } from 'react';
import { ClientImages } from "../../../../api";
import "./LogoFooter.scss";

const clientImagesController = new ClientImages();

export function LogoFooter() {
    const [logoImage, setLogoImage] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await clientImagesController.getClientImages({ code: "footer-logo" });
                
                const logoData = response.docs.find(doc => doc.code === "footer-logo");
                if (logoData && logoData.miniature) {
                    // Directamente usas la URL recibida de la API
                    setLogoImage(logoData.miniature);
                }
            } catch (error) {
                console.error("Error loading logo image:", error);
            }
        })();
    }, []);

    if (!logoImage) {
        return null; // O algún placeholder mientras carga
    }

    return (
        <div className="logo-footer">
            <img className="logo-footer__image" src={logoImage} alt="Inicio Logo" />
        </div>
    );
}
