import { ReactComponent as LogoWhite } from "./svg/tincode-white.svg";

import authBg from "./jpg/auth-bg.jpg";
import homeBanner from "./jpg/home-banner.jpg";
import noAvatar from "./jpg/no-avatar.jpg";
import academyLogo from "./png/academy-logo.png";
import logo from "./png/short logo GRELL.png";
import CMNexastrat from "./png/CMNexastrat.png";
import Diversidad from "./png/Diversidad.png";
import GORE from "./png/GORE.png";
import Ministerio from "./png/Ministerio.png";
import CNE from "./png/CNE.png";


const Icon = {
  LogoWhite,
};

const image = {
  authBg,
  homeBanner,
  noAvatar,
  academyLogo,
  logo,
  CMNexastrat,
  Diversidad,
  GORE,
  Ministerio,
  CNE,

};

export { Icon, image };