import React, { useState, useEffect, useRef } from 'react';
import './LineamientosContent.scss';
import { ClientImages } from "../../../../api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const clientImagesController = new ClientImages();

const codigosCarrusel = ["LIN_1", "LIN_2", "LIN_3", "LIN_4", "LIN_5", "LIN_6", "LIN_7", "LIN_8", "LIN_9", "LIN_10"];
const codigosImagen = ["LIN_IMAGENES_1", "LIN_IMAGENES_2", "LIN_IMAGENES_3", "LIN_IMAGENES_4", "LIN_IMAGENES_5", "LIN_IMAGENES_6", "LIN_IMAGENES_7", "LIN_IMAGENES_8", "LIN_IMAGENES_", "LIN_IMAGENES_10"];

const textosObjetivos = {
    LIN_1: "El PER es el principal instrumento que guía la política y gestión educativa regional. Al estar enmarcado en el Proyecto Educativo Nacional al 2036 (PEN) y el Plan de Desarrollo Regional Concertado (PDRC), asegura que las acciones educativas estén alineadas con los objetivos nacionales y regionales a largo plazo",
    LIN_2: "El PER es de cumplimiento obligatorio para todas las instituciones educativas, tanto públicas como privadas, en todos los niveles y modalidades. Esta obligatoriedad garantiza la aplicación uniforme de las políticas y lineamientos educativos en la región La Libertad, con el objetivo de mejorar la calidad educativa de manera equitativa.",
    LIN_3: "El PER se implementará por períodos de gestión del Gobierno Regional, que tienen una duración de cuatro años. Esta temporalidad permite una planificación y ejecución a mediano plazo, con la posibilidad de ajustar estrategias y metas de acuerdo con los cambios y avances en el contexto educativo.",
    LIN_4: "El PER sirve como marco para elaborar e implementar los Proyectos Educativos Locales (PEL) en cada provincia de la región. Esto permite adaptar las políticas regionales a la realidad local, promoviendo una gestión educativa descentralizada y contextualizada.",
    LIN_5: "El PER se enfocará en ocho ejes prioritarios establecidas para el período 2023-2026. Estos ejes abarcan aspectos relevantes para el desarrollo educativo regional: mejoramiento de los aprendizajes; formación docente; gestión educativa; infraestructura educativa, educación inclusiva; educación técnico productiva; tecnología educativa; educación superior.",
    LIN_6: "El PER se concreta en los instrumentos de planificación multianual, como el Proyecto Educativo Institucional (PEI), y en los instrumentos de planificación anual, como el Plan Operativo Institucional (POI). Estos instrumentos aseguran la implementación y seguimiento de las políticas establecidas en el PER.",
    LIN_7: "El PER debe ser considerado en el desarrollo de programas y proyectos educativos llevados a cabo por instituciones públicas y privadas de la región, así como por aliados estratégicos. Esto permite una articulación efectiva de las acciones y recursos, maximizando el impacto de las intervenciones educativas en beneficio de los estudiantes.",
    LIN_8: "El PER es la base para el diseño del Currículo Regional de La Libertad. Esto asegura que el currículo esté alineado con los objetivos y enfoques establecidos en el PER, brindando una educación pertinente a la realidad regional y las necesidades de los estudiantes.",
    LIN_9: "A nivel de la Gerencia Regional de Educación (GRE) y las Unidades de Gestión Educativa Local (UGEL), se utilizará un Balanced Scorecard (Cuadro de Mando Integral) como herramienta de gestión y seguimiento. Esto permite medir y evaluar el avance en relación con los indicadores y metas establecidos en el PER, facilitando la toma de decisiones informadas.",
    LIN_10: "La GRE La Libertad, en conjunto con el Consejo Participativo Regional en Educación (COPARE), realizará un evento anual para presentar y evaluar los avances en la implementación del PER. Este evento tiene como objetivo promover la transparencia, rendición de cuentas y el intercambio de experiencias entre los actores educativos de la región.",
  };

  const indexLIN1 = codigosCarrusel.indexOf("LIN_1");
  const initialSlideIndex = indexLIN1 !== -1 ? indexLIN1 : 0;

export function LineamientosContent() {
  const [objetivos, setObjetivos] = useState([]);
  const [objetivoSeleccionado, setObjetivoSeleccionado] = useState(null);
  const sliderRef = useRef();  // Crea una referencia para el slider

  useEffect(() => {
    const cargarTodosLosDatos = async () => {
      try {
        let todosLosDatos = [];
        let paginaActual = 1;
        let hayMasPaginas = true;
  
        while (hayMasPaginas) {
          const respuesta = await clientImagesController.getClientImages({ page: paginaActual });
          todosLosDatos = todosLosDatos.concat(respuesta.docs);
  
          if (respuesta.hasNextPage) {
            paginaActual += 1;
          } else {
            hayMasPaginas = false;
          }
        }
  
        const objetivosData = codigosCarrusel.map((codigo, index) => {
          const datoImagenCarrusel = todosLosDatos.find(dato => dato.code === codigo);
          const codigoImagenAsociada = codigosImagen[index];
          const datoImagenAsociada = todosLosDatos.find(dato => dato.code === codigoImagenAsociada);
        
          if (datoImagenCarrusel && datoImagenAsociada) {
            return {
              id: codigo,
              texto: textosObjetivos[codigo],
              imagenCarrusel: datoImagenCarrusel.miniature,
              imagenAsociada: datoImagenAsociada.miniature,
            };
          } else {
            return null;
          }
        }).filter(obj => obj !== null);
  
        setObjetivos(objetivosData);
        sliderRef.current.slickGoTo(initialSlideIndex);
      } catch (error) {
        console.error("Error cargando datos:", error);
      }
    };
  
    cargarTodosLosDatos();
  }, []);
  

  const handleChangeObjetivo = (codigo) => {
    setObjetivoSeleccionado(objetivos.find(objetivo => objetivo.id === codigo));
  };

  const PrevArrow = ({ onClick }) => (
    <button className="slick-prev" onClick={onClick} aria-label="Previous">{"<"}</button>
  );
  
  const NextArrow = ({ onClick }) => (
    <button className="slick-next" onClick={onClick} aria-label="Next">{">"}</button>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: initialSlideIndex,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: initialSlideIndex,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: initialSlideIndex,
        }
      }
    ]
  };

  return (
    <div className="lineamientosObjetivos">
      <h2>Lineamientos para la implementación del PER La Libertad al 2036</h2>
      <div className="slider-container">
        <Slider ref={sliderRef}  {...sliderSettings}>
          {objetivos.map(objetivo => (
            <div key={objetivo.id} onClick={() => handleChangeObjetivo(objetivo.id)}>
              <img src={objetivo.imagenCarrusel} alt={`Lineamiento ${objetivo.id}`} />
            </div>
          ))}
        </Slider>
      </div>

      {objetivoSeleccionado && (
        <div className={`objetivosDetalle ${objetivoSeleccionado ? "activo" : ""}`}>
          <div className="objetivosTexto">{objetivoSeleccionado?.texto}</div>
          <div className="objetivosImagen">
            <img src={objetivoSeleccionado.imagenAsociada} alt={`Detalle del objetivo ${objetivoSeleccionado.id}`} />
          </div>
        </div>
      )}
    </div>
  );
}
