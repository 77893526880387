import React, { useState } from 'react';
import {Tab, Button} from "semantic-ui-react";
import {BasicModal} from "../../../components/Shared"
import "./PowerBi.scss";
import {ListPowerBi, PowerBiForm} from "../../../components/Admin/PowerBi"


export function PowerBi() {

  const [showModal, setShowModal] = useState(false);
  const [reload, setReload ] = useState(false);

  const onOpenCloseModal = () => setShowModal ((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);

  const panes = [
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListPowerBi reload={reload} onReload={onReload}/>
        </Tab.Pane>
      ),
    },
  ]

  return (
    <>
      <div className='powerbi-page'>
        <div className='powerbi-page__add'>
          <Button primary onClick={onOpenCloseModal}>
            Nuevo Reporte
          </Button>
        </div>

        <Tab menu={{secondary: true }} panes={panes} />

        

      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title="Crear PowerBi">
        <PowerBiForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  )
}


