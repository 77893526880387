import React, {useState, useEffect, useRef} from 'react'
import { ClientImages, PowerBi } from "../../../../api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./PrioridadesObjetivos.scss";

const clientImagesController = new ClientImages();
const powerBiController = new PowerBi();

const codigosValidos = ["PRI_1", "PRI_2", "PRI_3", "PRI_4", "PRI_5", "PRI_6", "PRI_7", "PRI_8"];
const textosPrioridades = {
  PRI_1: "Este eje se enfoca en implementar estrategias y políticas que promuevan el logro de aprendizajes de calidad por parte de los estudiantes. Implica la identificación de metodologías efectivas de enseñanza y evaluación, el fortalecimiento de los programas curriculares, el desarrollo de habilidades y competencias clave, y la implementación de sistemas de seguimiento y evaluación del progreso académico de los estudiantes.",
  PRI_2: "Este eje se centra en el desarrollo profesional continuo de los docentes, brindándoles oportunidades de capacitación y actualización para mejorar sus prácticas pedagógicas. Incluye programas de formación, talleres, asesoramiento y acompañamiento docente, promoviendo el intercambio de experiencias y la adopción de enfoques innovadores en la enseñanza.",
  PRI_3: "Este eje busca mejorar la eficacia y eficiencia de la gestión educativa a nivel regional. Implica fortalecer capacidades en la planificación estratégica, la administración de recursos, la gestión financiera, la supervisión y evaluación de las instituciones educativas, así como promover la transparencia y rendición de cuentas en la gestión educativa.",
  PRI_4: "Este eje se enfoca en garantizar la existencia de espacios educativos adecuados y seguros para los estudiantes. Incluye el saneamiento físico legal, la construcción, ampliación o rehabilitación de infraestructuras educativas, como aulas, laboratorios, bibliotecas, espacios deportivos, así como la provisión de servicios básicos, mobiliario y materiales didácticos.",
  PRI_5: "Este eje busca garantizar que todos los estudiantes tengan igualdad de oportunidades de acceso y participación en la educación, sin importar su diversidad, capacidades o condiciones socioeconómicas. Implica implementar políticas y prácticas que promuevan la inclusión de estudiantes con discapacidad, poblaciones vulnerables, minorías étnicas, migrantes y otros grupos marginados, así como adaptar los entornos educativos para satisfacer sus necesidades específicas.",
  PRI_6: "Este eje se centra en promover la educación técnica y vocacional como una opción relevante y de calidad para los estudiantes. Busca fortalecer los programas de formación técnica (CETPRO y EBA), establecer vínculos con el sector productivo, facilitar la inserción laboral de los egresados y promover el espíritu empresarial y la innovación.",
  PRI_7: "Este eje se refiere a la integración efectiva de la tecnología en los procesos educativos mediante la universalización del servicio de internet en las IIEE. Implica el uso de recursos digitales, plataformas educativas, herramientas tecnológicas y medios audiovisuales para mejorar la enseñanza y el aprendizaje, así como fortalecer la gestión administrativa y el acceso a la información educativa.",
  PRI_8: "Este eje busca fortalecer la relación entre la educación superior y el desarrollo productivo de la región. Incluye el proceso de licenciamiento y acreditación de los IESP IEST, la promoción de programas académicos pertinentes a las necesidades del entorno productivo, la vinculación entre universidades y empresas, la formación de emprendedores y el fomento de la investigación y la innovación como motores del desarrollo regional.",

};
  const indexLIN1 = codigosValidos.indexOf("PRI_1");
  const initialSlideIndex = indexLIN1 !== -1 ? indexLIN1 : 0;

export function PrioridadesObjetivos() {
  const [prioridades, setPrioridades] = useState([]);
  const [prioridadesSeleccionadas, setPrioridadesSeleccionadas] = useState(null);
  const sliderRef = useRef();  // Crea una referencia para el slider


  useEffect(() => {
    const cargarDatos = async () => {
      try {
        // Carga acumulativa de los datos de ClientImages y PowerBi
        let todosLosDatosImagenes = await cargarTodosLosDatos(clientImagesController.getClientImages.bind(clientImagesController));
        let todosLosDatosPowerBi = await cargarTodosLosDatos(powerBiController.getPowerBi.bind(powerBiController));
        // Filtrado y mapeo usando codigosValidos
        const prioridadesData = codigosValidos.flatMap(codigo => {
          const datoPowerBi = todosLosDatosPowerBi.find(dato => dato.code === codigo);
          const datoImagen = todosLosDatosImagenes.find(dato => dato.code === codigo);
          
          if (datoPowerBi && datoImagen) {
            return [{
              id: codigo,
              icono: datoPowerBi.miniature,
              texto: textosPrioridades[codigo],
              imagen: datoImagen.miniature,
              powerBi: datoPowerBi.url
            }];
          }
          return [];
        });

        setPrioridades(prioridadesData);
      } catch (error) {
        console.error("Error cargando datos:", error);
      }
    };

    const cargarTodosLosDatos = async (funcionCargar) => {
      let todosLosDatos = [];
      let paginaActual = 1;
      let hayMasPaginas = true;

      while (hayMasPaginas) {
        const respuesta = await funcionCargar({ page: paginaActual });
        todosLosDatos = todosLosDatos.concat(respuesta.docs);

        if (respuesta.hasNextPage) {
          paginaActual += 1;
        } else {
          hayMasPaginas = false;
        }
      }

      return todosLosDatos;

    }

    if(window.innerWidth < 700) { // Asume que 700 es tu breakpoint para móviles
      sliderRef.current.slickGoTo(initialSlideIndex);
    }

    cargarDatos();
  }, [prioridades]);


  const handleChangePrioridades = (codigo) => {
    setPrioridadesSeleccionadas(prioridades.find(objetivo => objetivo.id === codigo));
  };

  const PrevArrow = ({ onClick }) => (
    <button className="slick-prev" onClick={onClick} aria-label="Previous">
      {"<"} 
    </button>
  );
  
  const NextArrow = ({ onClick }) => (
    <button className="slick-next" onClick={onClick} aria-label="Next">
      {">"} 
    </button>
  );
  

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: initialSlideIndex,
    responsive: [
      {
        breakpoint: 1620,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1015,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          initialSlide: initialSlideIndex,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          initialSlide: initialSlideIndex,
        }

      }
    ]
  };

  return (
    <div className="prioridades-objetivos">

      <div className="slider-container" >
      <Slider ref={sliderRef} {...sliderSettings}>
        {prioridades.map(prioridades => (
          <div key={prioridades.id} onClick={() => handleChangePrioridades(prioridades.id)}>
            <img src={prioridades.icono} alt={`Prioridad ${prioridades.id}`} />
          </div>
        ))}
      </Slider>

      </div>

      {prioridadesSeleccionadas && (
      <div className={`objetivosDetalle activo`} style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="objetivosTexto" style={{ flex: 1 }}>
          {prioridadesSeleccionadas.texto}
        </div>
        <div className="objetivosImagen" style={{ flex: 1 }}>
          <img src={prioridadesSeleccionadas.imagen} alt={`Detalle del objetivo ${prioridadesSeleccionadas.id}`} />
        </div>
      </div>
    )}

    {prioridadesSeleccionadas && (
      <div className={`objetivosPowerBi activo`}>
        <iframe
          src={prioridadesSeleccionadas.powerBi}
          frameBorder="0"
          allowFullScreen={true}
          width="100%"
          title={`Objetivo ${prioridadesSeleccionadas.id} - PowerBi`}
        ></iframe>
      </div>
    )}

    </div>
  )
}
