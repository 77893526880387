import React from 'react';
import { BSCBanner, BSCDescription, BSCObjectives } from "../../../components/Web/BSC"

export function BSC() {
  return (
    <div>
      <BSCBanner />
      <BSCDescription />
      <BSCObjectives />
    </div>
  )
}
