import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ClientLayout } from "../layouts";
import { Home, Per, Lineamientos, Prioridades, BSC, Repositorio } from "../pages/web";

export function WebRouter() {
  const loadLayout = ({ Layout, Page }) => (
    <Layout>
      <Page />
    </Layout>
  );

  return (
    <Routes>
      <Route path="/" element={loadLayout({ Layout: ClientLayout, Page: Home })} />
      <Route path="/per" element={loadLayout({ Layout: ClientLayout, Page: Per })} />
      <Route path="/lineamientos" element={loadLayout({ Layout: ClientLayout, Page: Lineamientos })} />
      <Route path="/prioridades" element={loadLayout({ Layout: ClientLayout, Page: Prioridades })} />
      <Route path="/bsc" element={loadLayout({ Layout: ClientLayout, Page: BSC })} />
      <Route path="/repositorio" element={loadLayout({Layout: ClientLayout,Page: Repositorio })} />
    </Routes>
  );
}