import React from 'react';
import "./AdminLayout.scss";
import {image} from "../../assets";
import {AdminMenu, Logout} from "../../components/Admin/AdminLayout"


export function AdminLayout(props) {
    const { children } = props;
  return (
    <div className='admin-layout'>
      <div className='admin-layout__left'>
        <div className='admin-layout__left-logocontainer'>
          <img src={image.logo} alt="Logo" className="logo" />
        </div>
        
        <AdminMenu />
      </div>
      <div className='admin-layout__right'>
        <div className='admin-layout__right-header'>
          <Logout />
        </div>
        <div className='admin-layout__right-content'>
          {children}
        </div>

      </div>
    </div>
  )
}
