import React from 'react'
import { Container } from "semantic-ui-react";
import { TopBar, Footer } from "../../components/Web";
import "./ClientLayout.scss"

export function ClientLayout(props) {
    const { children } = props;
    return (
        <div className='client-layout'>
            <div className='client-layout__header'>
                <TopBar />
            </div>
            
            {children}
            
            <div className='client-layout__footer'>
                <Container>
                    <Footer.LogoFooter />
                    <Footer.Mapa />
                    <Footer.Contacto />
                    <Footer.Social />
                </Container>
                <Container className="bottom-container">
                    <span>© 2023 Gerencia Regional de Educación La Libertad. Todos los derechos reservados</span>
                </Container>
            </div>
        </div>
    )
}
