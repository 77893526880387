import React from 'react';
import {PrioridadesBanner, PrioridadesDescription, PrioridadesObjetivos} from "../../../components/Web"

export function Prioridades() {
  return (
    <div>
      <PrioridadesBanner />
      <PrioridadesDescription />
      <PrioridadesObjetivos />
    </div>
  )
}
