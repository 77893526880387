import React, { useState, useEffect } from 'react';
import { ClientImages } from "../../../../api";
import "../../Prioridades/Banner/PrioridadesBanner.scss"

const clientImagesController = new ClientImages();

export function RepositorioBanner() {
  const [logoImage, setLogoImage] = useState("");

  useEffect(() => {
    const cargarImagenDelBanner = async () => {
      try {
        // Solo carga la imagen específica para el banner
        const response = await clientImagesController.getClientImages({ code: "Repositorio_Banner" });
       
        const logoData = response.docs.find(doc => doc.code === "Repositorio_Banner");
   
        if (logoData && logoData.miniature) {
          setLogoImage(logoData.miniature);
        }
      } catch (error) {
        console.error("Error loading logo image:", error);
      }
    };

    cargarImagenDelBanner();
  }, []);


  if (!logoImage) {
    return null; // O algún placeholder mientras carga
  }

  return (
    <div className="prioridades-banner" style={{ backgroundImage: `url(${logoImage})` }}>
      {/* No se necesita el <img> aquí */}
    </div>
  );
}
