import React, { useState, useEffect } from 'react';
import { ClientImages } from "../../../../api";
import "./PrioridadesBanner.scss"

const clientImagesController = new ClientImages();

export function PrioridadesBanner() {
  const [logoImage, setLogoImage] = useState("");

  useEffect(() => {
    const cargarImagenDelBanner = async () => {
      try {
        let paginaActual = 1;
        let hayMasPaginas = true;
        let logoData = null;

        while (hayMasPaginas && !logoData) {
          const response = await clientImagesController.getClientImages({ page: paginaActual, limit: 10 });
          

          logoData = response.docs.find(doc => doc.code === "PRI_Banner");

          if (response.hasNextPage) {
            paginaActual += 1;
          } else {
            hayMasPaginas = false;
          }
        }

        if (logoData && logoData.miniature) {
          setLogoImage(logoData.miniature);

        }
      } catch (error) {
        console.error("Error loading logo image:", error);
      }
    };

    cargarImagenDelBanner();
  }, []);




  if (!logoImage) {
    return null; // O algún placeholder mientras carga
  }

  return (
    <div className="prioridades-banner" style={{ backgroundImage: `url(${logoImage})` }}>
      {/* No se necesita el <img> aquí */}
    </div>
  );
}
