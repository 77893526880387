import { ENV } from "../utils";

export class Repositorio {
  baseApi = ENV.BASE_API;

  async createEntry(accessToken, data) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.REPOSITORIO}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data, 
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getEntries(params) {
    try {
      const pageFilter = `page=${params?.page || 1}`;
      const limitFilter = `limit=${params?.limit || 10}`;
      const yearFilter = params?.year ? `&year=${params.year}` : '';
      const categoryFilter = params?.category ? `&category=${params.category}` : '';

      const url = `${this.baseApi}/${ENV.API_ROUTES.REPOSITORIO}?${pageFilter}&${limitFilter}${yearFilter}${categoryFilter}`;

      const response = await fetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;
      return result;

    } catch (error) {
      throw error;
    }
  }
 
  

  async updateEntry(accessToken, idEntry, data) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.REPOSITORIO}/${idEntry}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          
        },
        body: data,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteEntry(accessToken, entryId) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.REPOSITORIO}/${entryId}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }
}