import React, {useState, useEffect} from 'react';
import { ClientImages} from "../../../../api";
import "./PrioridadesDescription.scss"

const clientImagesController = new ClientImages();

export function PrioridadesDescription() {

  const [prioridadesImagen, setPrioridadesImagen] = useState("");

  useEffect(() => {
    const cargarImagenDelBanner = async () => {
      try {
        // Solo carga la imagen específica para el banner
        const response = await clientImagesController.getClientImages({ code: "PRI_PRI" });
       
        const prioridadesImagenData = response.docs.find(doc => doc.code === "PRI_PRI");
   
        if (prioridadesImagenData && prioridadesImagenData.miniature) {
          setPrioridadesImagen(prioridadesImagenData.miniature);
        }
      } catch (error) {
        console.error("Error loading logo image:", error);
      }
    };

    cargarImagenDelBanner();
  }, []);


  if (!prioridadesImagen) {
    return null; // O algún placeholder mientras carga
  }

  return (
    <div className='prioridades-description'>

      <div className='prioridades-description__definition'>
        <div className='prioridades-description__definition__left'>
          <img  className='prioridades-description__definition__left__image' src={prioridadesImagen} alt='Proyecto Educativo Local'/>
        </div>

        <div className='prioridades-description__definition__right'>
          <h1>Prioridades Educativas 2023 - 2026</h1>
          <p>Las prioridades educativas se refieren a las políticas educativas que el gobierno regional considera más urgentes de abordar. La decisión de implementar las políticas educativas por periodos de gestión de cuatro años, permite optimizar los recursos y concentrar esfuerzos en los ocho ejes que se consideran prioritarios, asegurando una implementación más efectiva y adaptada a la realidad en constante cambio del sistema educativo.</p>
        </div>
        
        
      </div>

    </div>
  )
}
