import React, {useState} from 'react';
import {Tab} from "semantic-ui-react";
import "./Auth.scss";
import { LoginForm } from "../../../components/Admin/Auth"
import {image} from "../../../assets";

export function Auth() {

  const [activeIndex, setActiveIndex] = useState(0);
  


  const panes = [
    {
      menuItem: "Entrar",
      render : () => (
        <Tab.Pane>
          <LoginForm />
        </Tab.Pane>
      )
    }
  ]

  return (
    <div className='auth'>
      <img src={image.logo} alt="Logo" className="logo" />

      <Tab panes = {panes} className="auth__form" activeIndex={activeIndex} onTabChange={(_, data) => setActiveIndex(data.activeIndex)}/>
    </div>
  )
}
