import React from 'react';
import {LineamientosBanner, LineamientosContent} from "../../../components/Web/Lineamientos"

export function Lineamientos() {
  return (
    <div>
      <LineamientosBanner />
      <LineamientosContent />

    </div>
  )
}
