import React from 'react';
import "./Info.scss"




export function Info() {
  return (
    <div className='info__inicio'>

      <h3> Fomentamos la excelencia educativa </h3>
      <h1>Construyendo un futuro lleno de oportunidades</h1>

    </div>
  )
}
