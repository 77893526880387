import React, { useState, useEffect } from 'react';
import { ClientImages } from "../../../../api";
import "./Banner.scss";

const clientImagesController = new ClientImages();

export function Banner() {
  const [logoBanner, setLogoBanner] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const responses = await Promise.all([
          clientImagesController.getClientImages({ code: "banner-inicio" }),
          clientImagesController.getClientImages({ code: "inicio-logo" })
        ]);

        // Procesar la respuesta para banner-inicio
        const logoBannerData = responses[0].docs.find(doc => doc.code === "banner-inicio");
        if (logoBannerData && logoBannerData.miniature) {
          setLogoBanner(logoBannerData.miniature);
        }

        // Procesar la respuesta para inicio-logo
        const logoImageData = responses[1].docs.find(doc => doc.code === "inicio-logo");
        if (logoImageData && logoImageData.miniature) {
          setLogoImage(logoImageData.miniature);
        }
      } catch (error) {
        console.error("Error loading images:", error);
      }
    })();
  }, []);

  if (!logoBanner || !logoImage) {
    return null; 
  }

  return (
    <div className="banner">
      <div className="banner-left">
        <div className='banner-left__logo'>
        <img className="banner-left__logo__image" src={logoImage} alt="imagen logo" />
        </div>
        
        <div className="banner-left__text">
          <h1>Modelo de implementación del <br /> Proyecto Educativo Regional de La Libertad</h1>
          <p>El Modelo de implementación del Proyecto Educativo Regional de  La Libertad se fundamenta en la integración de lineamientos y  prioridades estratégicas, respaldado por tecnologías innovadoras, con el objetivo de fortalecer la gestión educativa mediante el seguimiento y evaluación del cumplimiento de las políticas regionales.</p>
        </div>
      </div>

      <div className="banner-right">
        <img className="banner-right__image" src={logoBanner} alt="imagen inicio" />
      </div>
    </div>
  );
}
