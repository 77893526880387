import * as Yup from 'yup';

export function initialValues(repositorio = {}) {
  return {
    title: repositorio.title || '',
    pdf: null,  // Set to null for initial file upload field
    category: repositorio.category || '',
    code: repositorio.code || '',
    coverImage: null,  // Set to null for initial file upload field
    year: repositorio.year || '',
  };
}

// Esquema de validación para el formulario
export function validationSchema() {
  return Yup.object({
    title: Yup.string().required("Title is required"),
    pdf: Yup.mixed().required("PDF file is required").test(
      "fileType",
      "Only PDF files are allowed",
      value => value && value.type === "application/pdf"
    ),
    category: Yup.string().required("Category is required"),
    code: Yup.string().required("Code is required"),
    coverImage: Yup.mixed().required("Cover image is required").test(
      "fileType",
      "Only image files are allowed",
      value => value && ["image/jpeg", "image/png"].includes(value.type)
    ),
    year: Yup.number().required("Year is required").positive("Year must be a positive number").integer("Year must be an integer"),
  });
}
