import React, { useState, useEffect, createContext, useCallback } from "react";
import { User, Auth } from "../api";
import { hasExpiredToken } from "../utils";

// Inicialización de las clases para el manejo de usuarios y autenticación
const userController = new User();
const authController = new Auth();

// Creación del contexto de autenticación
export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // Corregido el error tipográfico aquí

  // Definición de la función de cierre de sesión
  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    authController.removeTokens();
  }, []);

  // Definición de la función de inicio de sesión
  const login = useCallback(async (accessToken) => {
    try {
      const response = await userController.getMe(accessToken);
      if (response) delete response.password;
      setUser(response);
      setToken(accessToken);
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Definición de la función para reautenticar al usuario
  const reLogin = useCallback(async (refreshToken) => {
    try {
      const { accessToken } = await authController.refreshAccessToken(refreshToken);
      if (accessToken) {
        authController.setAccessToken(accessToken);
        await login(accessToken);
      }
    } catch (error) {
      logout();
      console.error(error);
    }
  }, [login, logout]);

  // Efecto para manejar la autenticación inicial y la reautenticación
  useEffect(() => {
    (async () => {
      const accessToken = authController.getAccessToken();
      const refreshToken = authController.getRefreshToken();

      if (!accessToken || !refreshToken) {
        logout();
      } else if (hasExpiredToken(accessToken)) {
        if (hasExpiredToken(refreshToken)) {
          logout();
        } else {
          await reLogin(refreshToken);
        }
      } else {
        await login(accessToken);
      }

      setLoading(false);
    })();
  }, [reLogin, logout, login]);

  // Preparación de los datos del contexto
  const data = {
    user,
    accessToken: token,
    login,
    logout,
  };

  // Renderizado condicional basado en el estado de carga
  if (loading) return null;

  // Proveedor del contexto
  return (
    <AuthContext.Provider value={data}>
      {children}
    </AuthContext.Provider>
  );
}