import React, { useCallback, useState, useEffect } from "react";
import { Form, Image } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { Repositorio } from "../../../../api";
import { useAuth } from "../../../../hooks";
import {image} from "../../../../assets"
import { initialValues, validationSchema } from "./Repositorio.form";
import "./Repositorio.scss";

const repositorioController= new Repositorio();


export function RepositorioForm(props) {
  const { onClose, onReload, repositorio } = props
  const { accessToken } = useAuth();
  const [pdfName, setPdfName] = useState('');

  useEffect(() => {
    if (repositorio && repositorio.pdf) {
      // Extrae el nombre del archivo desde la URL
      const urlParts = repositorio.pdf.split('/');
      setPdfName(urlParts[urlParts.length - 1]);
    }
  }, [repositorio]);
  

  const formik = useFormik({
    initialValues: initialValues(repositorio),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValues) => {

      try {
      const formData = new FormData();
      Object.keys(formValues).forEach(key => {
        if ( key !== "coverImage" && key !== "pdf") {
          formData.append(key, formValues[key]);
        }
      });

      if (formValues.coverImage) {
        formData.append("coverImage", formValues.coverImage);
      }
      if (formValues.pdf) {
        formData.append("pdf", formValues.pdf);
      }

      

        
        if (!repositorio) {
          await repositorioController.createEntry(accessToken, formData);
        } else {
          await repositorioController.updateEntry(accessToken, repositorio._id, formData);
        }

        
          onReload();
          onClose();
        
      } catch (error) {
        console.error(error);
      }
    },
  });


  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      if (file.type === "application/pdf") {
        formik.setFieldValue("pdf", file);
      } else if (file.type.startsWith("image/")) {
        formik.setFieldValue("coverImage", file);
      }
    });
  }, [formik]);

  const { getRootProps, getInputProps } = useDropzone({
    accepts: ["image/*", "application/pdf"],
    multiple: true,
    onDrop,
    
  });

  const handlePdfInputChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("pdf", file);
      setPdfName(file.name);  // Actualizar el estado con el nombre del nuevo PDF
    }
  };



  const getMiniature = () => {
    // Asegúrate de que formik.values y formik.values.coverImage están definidos
    if (formik.values && formik.values.coverImage) {
      // Si hay una imagen seleccionada, crea una URL y devuélvela
      return URL.createObjectURL(formik.values.coverImage);
    } else if (repositorio && repositorio.coverImage) {
      // Si ya existe una imagen de portada en el repositorio, devuélvela
      return repositorio.coverImage;
    }
    // Si no hay imagen, devuelve la imagen por defecto
    return image.noAvatar;
  };

  return (
    <Form className="repositorio-form" onSubmit={formik.handleSubmit}>
      <div className="file-upload-section">
        <div className="upload-circle" {...getRootProps()}>
          <input {...getInputProps()} />
          {getMiniature() ? (
          <Image size="small" src={getMiniature()} />
        ) : (
          <div>
            <span>Arrastra tu miniatura</span>
          </div>
        )}
        </div>
        <div className="upload-pdf-button">
          {pdfName && <div>{pdfName}</div>} 
          <button type="button" onClick={() => document.getElementById('pdfInput').click()}>
            Upload PDF
          </button>
          <input
          type="file"
          id="pdfInput"
          hidden
          onChange={handlePdfInputChange}
          accept="application/pdf"
        />        </div>
      </div>
     

      <Form.Group widths="equal">
        <Form.Input
          name="title"
          placeholder="Nombre"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title}
        />
        <Form.Input
          name="year"
          type="number"
          placeholder="Año"
          onChange={formik.handleChange}
          value={formik.values.year}
          error={formik.errors.year}
        />


      </Form.Group>      
      
      <Form.Group widths="equal">
        <Form.Input
          name="category"
          placeholder="Categoría"
          onChange={formik.handleChange}
          value={formik.values.category}
          error={formik.errors.category}
        />
        <Form.Input
          name="code"
          placeholder="Código"
          onChange={formik.handleChange}
          value={formik.values.code}
          error={formik.errors.code}
        />

      </Form.Group>
      
      
      
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {!repositorio ? "Crear repositorio" : "Actualizar Repositorio"}
      </Form.Button>
    </Form>
  );
}
