import React, { useState, useEffect, useCallback } from 'react';
import { Repositorio } from "../../../../api";
import "./RepositorioDescription.scss";

const repositorioAPI = new Repositorio();

export function RepositorioDescription() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedYear, setSelectedYear] = useState('Todos');
  const [repositorios, setRepositorios] = useState([]);
  const [years, setYears] = useState([]);

  const loadRepositorios = useCallback(async () => {
    try {
      const params = selectedYear !== 'Todos' ? { year: selectedYear } : {};
      const result = await repositorioAPI.getEntries(params);
      if (result && result.docs) {
        setRepositorios(result.docs);
      } else {
        setRepositorios([]);
        console.error('La respuesta de la API no es un objeto válido');
      }
    } catch (error) {
      console.error(error);
      setRepositorios([]);
    }
  }, [selectedYear]);

  const loadYears = useCallback(async () => {
    const yearsFromDocs = new Set(repositorios.map(doc => doc.year.toString())); // Asegúrate de convertir a string
    setYears(['Todos', ...Array.from(yearsFromDocs).sort((a, b) => b - a)]);
  }, [repositorios]);
  
  useEffect(() => {
    loadRepositorios();
    loadYears();
  }, [loadRepositorios, loadYears]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year.toString());
  };

  const handleCardClick = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  const filteredRepositorios = repositorios.filter(repo => {
    return (repo.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedYear === 'Todos' || repo.year.toString() === selectedYear));
  });

  return (
    <div className="repositorio-description">
      <h1 className="title">Repositorio</h1>
      <div className="search-section">
        <input 
          type="text" 
          className="search-bar"
          placeholder="Buscar..." 
          value={searchTerm}
          onChange={handleSearchChange} 
        />
      </div>
      <div className="year-selector">
        {years.map(year => (
          <button
            key={year}
            onClick={() => handleYearButtonClick(year)}
            className={`year-button ${selectedYear === year ? 'active' : ''}`}
          >
            {year}
          </button>
        ))}
      </div>
      <div className="repositorio-grid">
        {filteredRepositorios.map((repo, index) => (
          <div key={index} className="repositorio-card" onClick={() => handleCardClick(repo.pdf)}>
            <div className="repositorio-image-container">
              <img 
                src={repo.coverImage} 
                alt={repo.title} 
                className="repositorio-image" 
              />
              <div className="repositorio-card-back">
                <p>{repo.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
