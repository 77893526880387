import * as Yup from 'yup';


export function initialValues(clientimage = {}) {
  return {
    title: clientimage.title || '',
    category: clientimage.category || '',
    code: clientimage.code || '',
    miniature: clientimage.miniature || '',
    file: null,
  };
}



// Esquema de validación para el formulario
export function validationSchema() {
    return Yup.object({
      title: Yup.string().required(true),
      category: Yup.string().required(true),
      code: Yup.string().required(true),
    });
  }
  
