import React from 'react';
import { Routes, Route } from "react-router-dom";
import { AdminLayout } from "../layouts";
import {useAuth} from "../hooks"

import { Auth, Users, PowerBi, Image, Menu, Repositorio} from "../pages/admin";




export function AdminRouter() {
  const {user} = useAuth();

  const loadLayout = ({ Layout, Page }) => (
    <Layout>
      <Page />
    </Layout>
  );

  // Rutas a renderizar si el usuario está autenticado
  const adminRoutes = ["/admin", "/admin/powerbi"];

  return (
    <Routes>
      {!user ? (
        // Si no hay usuario, redirigir a Auth
        <Route path="/admin/*" element={<Auth/>} />
      ) : (
        <>
          {adminRoutes.map((path) => (
            <Route key={path}
                   path={path}
                   element={loadLayout({ Layout: AdminLayout, Page: PowerBi })} />
          ))}

          <Route path="/admin/users" element={loadLayout({ Layout: AdminLayout, Page:  Users})} />
          <Route path="/admin/image" element={loadLayout({ Layout: AdminLayout, Page: Image })} />
          <Route path="/admin/menu" element={loadLayout({ Layout: AdminLayout, Page: Menu })} />
          <Route path="/admin/repositorio" element={loadLayout({Layout: AdminLayout, Page: Repositorio})} />
        </>
      )}
    </Routes>
  );
}