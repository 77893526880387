import React from 'react';
import { IoLocationOutline } from "react-icons/io5";
import { SiGmail } from "react-icons/si";
import "./Contacto.scss"





export function Contacto() {
  return (
    <div className='contacto'>
      <div className='contacto__title'>
        <h3>INFORMACIÓN DE CONTACTO</h3>
      </div>

      <div className='contacto__información'>
        <p className='contacto__información__direccion'><IoLocationOutline /> Av. América Sur 2870, Trujillo</p>
        <p className='contacto__información__correo'><SiGmail /> gerencia@gerll.com </p>
      </div>
      
    

    </div>
  )
}
