import React, {useState, useEffect, useRef} from 'react'
import { ClientImages, PowerBi } from "../../../../api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../../Prioridades/Objetivos/PrioridadesObjetivos.scss"


const clientImagesController = new ClientImages();
const powerBiController = new PowerBi();

const codigosValidos = ["BSC_1", "BSC_2", "BSC_3", "BSC_4"];
const textosPrioridades = {
  BSC_1: "En la perspectiva financiera del Balanced Scorecard, la Gerencia de Educación se enfoca en asegurar la sostenibilidad económica y financiera de la institución. A través de indicadores como la eficiencia en el uso de recursos, la gestión presupuestaria y la maximización de ingresos, buscamos garantizar la viabilidad a largo plazo para ofrecer una educación de calidad.",
  BSC_2: "Desde la perspectiva del usuario, nos esforzamos por entender y satisfacer las necesidades y expectativas de nuestros estudiantes y sus familias. Medimos la calidad del servicio educativo, la satisfacción del estudiante y la percepción de los padres, trabajando continuamente para mejorar la experiencia educativa y fortalecer nuestra relación con la comunidad educativa.",
  BSC_3: "En la perspectiva de procesos internos, nos centramos en la eficiencia y mejora continua de nuestras operaciones educativas. Evaluamos la efectividad de los métodos de enseñanza, la gestión de aulas y la implementación de tecnologías educativas. Buscamos optimizar los procesos para ofrecer una educación más efectiva y adaptada a las necesidades cambiantes de nuestros estudiantes.",
  BSC_4: "La perspectiva de aprendizaje y crecimiento se enfoca en el desarrollo y capacitación del personal educativo, así como en la mejora de la infraestructura y recursos tecnológicos. Medimos la formación continua del personal, la adopción de nuevas tecnologías y la infraestructura educativa, asegurando que nuestro equipo esté preparado para ofrecer una educación de calidad y mantenerse a la vanguardia de las tendencias educativas.",
 };
 const indexLIN1 = codigosValidos.indexOf("LIN_1");
const initialSlideIndex = indexLIN1 !== -1 ? indexLIN1 : 0;

export function BSCObjectives() {
  const [prioridades, setPrioridades] = useState([]);
  const [prioridadesSeleccionadas, setPrioridadesSeleccionadas] = useState(null);
  const sliderRef = useRef();  // Crea una referencia para el slider

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        // Carga acumulativa de los datos de ClientImages y PowerBi
        let todosLosDatosImagenes = await cargarTodosLosDatos(clientImagesController.getClientImages.bind(clientImagesController));
        let todosLosDatosPowerBi = await cargarTodosLosDatos(powerBiController.getPowerBi.bind(powerBiController));
        // Filtrado y mapeo usando codigosValidos
        const prioridadesData = codigosValidos.flatMap(codigo => {
          const datoPowerBi = todosLosDatosPowerBi.find(dato => dato.code === codigo);
          const datoImagen = todosLosDatosImagenes.find(dato => dato.code === codigo);
          
          if (datoPowerBi && datoImagen) {
            return [{
              id: codigo,
              icono: datoPowerBi.miniature,
              texto: textosPrioridades[codigo],
              imagen: datoImagen.miniature,
              powerBi: datoPowerBi.url
            }];
          }
          return [];
        });

        setPrioridades(prioridadesData);
      } catch (error) {
        console.error("Error cargando datos:", error);
      }
    };

    const cargarTodosLosDatos = async (funcionCargar) => {
      let todosLosDatos = [];
      let paginaActual = 1;
      let hayMasPaginas = true;

      while (hayMasPaginas) {
        const respuesta = await funcionCargar({ page: paginaActual });
        todosLosDatos = todosLosDatos.concat(respuesta.docs);

        if (respuesta.hasNextPage) {
          paginaActual += 1;
        } else {
          hayMasPaginas = false;
        }
      }

      return todosLosDatos;
    };
    if(window.innerWidth < 700) { // Asume que 700 es tu breakpoint para móviles
      sliderRef.current.slickGoTo(initialSlideIndex);
    }

    cargarDatos();
  }, [prioridades]);


  const handleChangePrioridades = (codigo) => {
    setPrioridadesSeleccionadas(prioridades.find(objetivo => objetivo.id === codigo));
  };

  const PrevArrow = ({ onClick }) => (
    <button className="slick-prev" onClick={onClick} aria-label="Previous">
      {"<"} 
    </button>
  );
  
  const NextArrow = ({ onClick }) => (
    <button className="slick-next" onClick={onClick} aria-label="Next">
      {">"} 
    </button>
  );
  

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: initialSlideIndex,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1015,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          initialSlide: initialSlideIndex,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          initialSlide: initialSlideIndex,
        }

      }
    ]
  };

  return (
    <div className="prioridades-objetivos">

      <div className="slider-container" >
      <Slider ref={sliderRef} {...sliderSettings}>
        {prioridades.map(prioridades => (
          <div key={prioridades.id} onClick={() => handleChangePrioridades(prioridades.id)}>
            <img src={prioridades.icono} alt={`Prioridad ${prioridades.id}`} />
          </div>
        ))}
      </Slider>

      </div>

      {prioridadesSeleccionadas && (
      <div className={`objetivosDetalle activo`} style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="objetivosTexto" style={{ flex: 1 }}>
          {prioridadesSeleccionadas.texto}
        </div>
        <div className="objetivosImagen" style={{ flex: 1 }}>
          <img src={prioridadesSeleccionadas.imagen} alt={`Detalle del objetivo ${prioridadesSeleccionadas.id}`} />
        </div>
      </div>
    )}

    {prioridadesSeleccionadas && (
      <div className={`objetivosPowerBi activo`}>
        <iframe
          src={prioridadesSeleccionadas.powerBi}
          frameBorder="0"
          allowFullScreen={true}
          width="100%"
          title={`Objetivo ${prioridadesSeleccionadas.id} - PowerBi`}
        ></iframe>
      </div>
    )}

    </div>
  )
}
