import React from 'react';
import {image} from "../../../../assets"
import "./Socios.scss";



export function Socios() {
  return (
    <div className='socios-inicio'>

      <div className='socios-inicio__title'>
        <div className='socios-inicio__title__socios'>
          <h2>Nuestros Socios</h2>
        </div>
        
      </div>

      <div className='socios-inicio__logos'>

        <div className='socios-inicio__logos__first'>
          <a href='https://www.gob.pe/regionlalibertad'  target='_blank' rel='noreferrer'><img src={image.GORE} alt='Gobierno Regional La Libertad'/> </a>
          <a href='https://www.cmnexastrat.com/'  target='_blank' rel='noreferrer'> <img src={image.CMNexastrat} alt='C & M Nexastrat' /></a>
          <img  src={image.Diversidad} alt='+Diversidad' />
          
          
          
        </div>
        <div className='socios-inicio__logos__second'>

          <a href='https://www.gob.pe/minedu'  target='_blank' rel='noreferrer'> <img src={image.Ministerio} alt='Ministerio de Educación' /></a>
          <a href='https://www.gob.pe/cne'  target='_blank' rel='noreferrer'> <img src={image.CNE} alt='Consejo Nacional de Educación' /></a>

          
          

        </div>
      </div>

    </div>
  )
}
