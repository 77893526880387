import React, { useCallback } from "react";
import { Form, Image } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { ClientImages } from "../../../../api";
import { useAuth } from "../../../../hooks";
import {image} from "../../../../assets"
import { initialValues, validationSchema } from "./ClientImagesForm.form";
import "./ClientImagesForm.scss";

const clientimagesController = new ClientImages();

export function ClientImagesForm(props) {
  const { onClose, onReload, clientimage } = props;
  const { accessToken } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(clientimage),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {

        const formData = new FormData();
        Object.keys(formValue).forEach(key => {
          if (key !== "file") {
          formData.append(key, formValue[key]);
          }
        });

        if (formValue.file) {
          formData.append("miniature", formValue.file); // Asegurarse de que este campo coincide con el esperado en el backend
          }


        if (!clientimage) {
          await clientimagesController.createClientImages(accessToken, formData);
        } else {
          await clientimagesController.updateClientImages(
            accessToken,
            clientimage._id,
            formData
          );
        }

        onReload();
        onClose();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    formik.setFieldValue("miniature", URL.createObjectURL(file));
    formik.setFieldValue("file", file);
  }, [formik]); 

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop,
  });

  const getMiniature = () => {
    if (formik.values.file) {
      return formik.values.miniature;
    } else if (formik.values.miniature) {
      return clientimage.miniature;
      
    }
    return image.noAvatar;
  };

  return (
    <Form className="clientimage-form" onSubmit={formik.handleSubmit}>
      <div className="clientimage-form__miniature" {...getRootProps()}>
        <input {...getInputProps()} />
        
        {getMiniature() ? (
          <Image size="small" src={getMiniature()} />
        ) : (
          <div>
            <span>Arrastra tu miniatura</span>
          </div>
        )}
      </div>

      
        <Form.Input
          name="title"
          placeholder="Nombre de la imagen"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title}
        />

        <Form.Group widths= "equal">
        <Form.Input 
        name="category"
        placeholder="categoria"
        onChange={formik.handleChange}
        value={formik.values.category}
        error={formik.errors.category}
        />

        <Form.Input
          name="code"
          placeholder="código"
          onChange={formik.handleChange}
          value={formik.values.code}
          error={formik.errors.code}
        />

        
      </Form.Group>


      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {!clientimage ? "Crear imagen" : "Actualizar imagen"}
      </Form.Button>
    </Form>
  );
}