import React, {useState, useEffect} from 'react';
import { ClientImages} from "../../../../api";
import "./Description.scss"

const clientImagesController = new ClientImages();

export function DescriptionPer() {

  const [imagePer, setImagePer] = useState(null);
  const [imageVision, setImageVision] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const responses = await Promise.all([
          clientImagesController.getClientImages({ code: "PER_PER" }),
          clientImagesController.getClientImages({ code: "PER_Vision" })
        ]);

        // Procesar la respuesta para PER
        const imagePerData = responses[0].docs.find(doc => doc.code === "PER_PER");
        if (imagePerData && imagePerData.miniature) {
          setImagePer(imagePerData.miniature);
        }

        // Procesar la respuesta para vision
        const imageVisionData = responses[1].docs.find(doc => doc.code === "PER_Vision");
        if (imageVisionData && imageVisionData.miniature) {
          setImageVision(imageVisionData.miniature);
        }
      } catch (error) {
        console.error("Error loading images:", error);
      }
    })();
  }, []);

  return (
    <div className='per-description'>

      <div className='per-description__definition'>
        <div className='per-description__definition__left'>
          <img  className='per-description__definition__left__image' src={imagePer} alt='Proyecto Educativo Local'/>
        </div>

        <div className='per-description__definition__right'>
          <h1>Proyecto Educativo Regional al 2036</h1>
          <p>El Proyecto Educativo Regional La Libertad al 2036 (PER) es el principal instrumento de <br/> gestión educativa regional. Se elabora en el marco del Proyecto Educativo Nacional y <br/> del Plan de Desarrollo Regional Concertado (PDRC), con la participación democrática <br/> de la sociedad organizada (LGE N.° 28044)</p>
        </div>
        
        
      </div>

      <div className='per-description__vision'>

        <div className='per-description__vision__left'>
          <h2>Visión del Proyecto Educativo Regional al 2036</h2>
          <p>“Al 2036 liberteñas y liberteños nos desarrollamos integralmente en cada ciclo de vida <br/>  a lo largo de la vida como ciudadanos y ciudadana plenas, con una educación de <br/> calidad, equitativa, accesible, moderna y en valores ciudadanos, a través de la <br/> articulación y compromiso de todos los actores en una adecuada convivencia <br/> intergeneracional e intercultural que contribuya a una sociedad inclusiva, equitativa y <br/> democrática, para lograr el desarrollo territorial sostenible y competitivo, basado en <br/> la ciencia, tecnología e innovación”.
  </p>  
        </div>

        <div className='per-description__vision__right'>
          <img className='per-description__vision__left__image' src={imageVision} alt='Vision del Per' />
        </div>

        

      </div>
    </div>
  )
}
