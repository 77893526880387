import { ENV } from "../utils";

export class PowerBi {
  baseApi = ENV.BASE_API;

  async getPowerBi(params) {
    try {
      const pageFilter = `page=${params?.page || 1}`;
      const limitFilter = `limit=${params?.limit || 10}`;
      const codeFilter = params?.code ? `&code=${params.code}` : '';

      const url = `${this.baseApi}/${ENV.API_ROUTES.POWERBI}?${pageFilter}&${limitFilter}${codeFilter}`;
      const response = await fetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async createPowerBi(accessToken, data) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.POWERBI}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async updatePowerBi(accessToken, idPowerBi, data) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.POWERBI}/${idPowerBi}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deletePowerBi(accessToken, idPowerBi) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.POWERBI}/${idPowerBi}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }
}
