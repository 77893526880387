// Asegúrate de tener importado useEffect y useState desde 'react'.
import React, { useEffect, useState } from 'react';
import { ClientImages } from "../../../../api";
import "./LineamientosBanner.scss";

const clientImagesController = new ClientImages();

export function LineamientosBanner() {
    const [logoImage, setLogoImage] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const response = await clientImagesController.getClientImages({ code: "LIN_Banner" });
                const logoData = response.docs.find(doc => doc.code === "LIN_Banner");
                if (logoData && logoData.miniature) {
                    setLogoImage(logoData.miniature);
                }
            } catch (error) {
                console.error("Error loading logo image:", error);
            }
        })();
    }, []);

    if (!logoImage) {
        return null; // O algún placeholder mientras carga
    }

    return (
        <div className="lineamiento-banner" style={{ backgroundImage: `url(${logoImage})` }}>
            {/* No se necesita el <img> aquí */}
        </div>
    );
}
