import React, { useState, useEffect } from 'react';
import { Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { image } from "../../../assets";
import { Menu } from "../../../api";
import "./TopBar.scss";

const menuController = new Menu();

export function TopBar() {
  const [menu, setMenu] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false); // Nuevo estado para controlar la visibilidad

  useEffect(() => {
    (async () => {
      try {
        const response = await menuController.getMenu(true);
        setMenu(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div className='top-bar'>
      <Container>
        <div className='top-bar__left'>
          <Link to="/" className='logo-container'>
            <img src={image.logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className='top-bar__right'>
          {/* Botón del menú hamburguesa */}
          <div className='hamburger-menu' onClick={() => setIsMenuVisible(!isMenuVisible)}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className={`menu ${isMenuVisible ? 'visible' : ''}`}>
            {map(menu, (item) => (
              <a key={item._id} href={item.path}>
                {item.title}
              </a>
            ))}
          </div>        
        </div>
      </Container>
    </div>
  )
}
