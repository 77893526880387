import * as Yup from 'yup';


export function initialValues(powerbi = {}) {
  return {
    title: powerbi.title || '',
    url: powerbi.url || '',
    code: powerbi.code || '',
    miniature: powerbi.miniature || '',
    file: null,
  };
}



// Esquema de validación para el formulario
export function validationSchema() {
    return Yup.object({
      title: Yup.string().required(true),
      url: Yup.string().url(true).required(true),
      code: Yup.string().required(true),
    });
  }
  
