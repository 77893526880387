import { ENV } from "../utils";

export class ClientImages {
  baseApi = ENV.BASE_API;

  async createClientImages(accessToken, data) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.CLIENTIMAGE}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 201) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getClientImages(params) {
    try {
      const pageFilter = `page=${params?.page || 1}`;
      const limitFilter = `limit=${params?.limit || 10}`;
      const codeFilter = params?.code ? `&code=${params.code}` : '';
      const url = `${this.baseApi}/${ENV.API_ROUTES.CLIENTIMAGE}?${pageFilter}&${limitFilter}${codeFilter}`;

      const response = await fetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateClientImages(accessToken, idClientImage, data) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.CLIENTIMAGE}/${idClientImage}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: data,
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteClientImage(accessToken, idClientImage) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.CLIENTIMAGE}/${idClientImage}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }
}
