import React, { useState, useEffect } from "react";
import { Loader, Pagination } from "semantic-ui-react";
import { size, map } from "lodash";
import { Repositorio } from "../../../../api";
import { RepositorioItem } from "../RepositorioItem";
import "./ListRepositorio.scss";


const repositorioController = new Repositorio();

export function ListRepositorio(props) {
  const { reload, onReload } = props;
  const [repositorios, setRepositorios] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await repositorioController.getEntries({ page });
        setRepositorios(response.docs);
        setPagination({
          limit: response.limit,
          page: response.page,
          totalPages: response.totalPages, 
          total: response.totalDocs, 
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  if (!repositorios) return <Loader active inline="centered" />;
  if (size(repositorios) === 0) return "No hay ninguna entrada";

  return (
    <div className="list-repositorios">
      {map(repositorios, (repositorio) => (
        <RepositorioItem key={repositorio._id} repositorio={repositorio} onReload={onReload} />
      ))}

      <div className="list-repositorios__pagination">
        <Pagination
          totalPages={pagination.totalPages} 
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
          />
      </div>
    </div>
  );
}