import { Social } from "./Social";
import {LogoFooter} from "./Logo";
import {Mapa} from "./Mapa";
import {Contacto} from "./Contacto"

export const Footer = {
    
    LogoFooter,
    Mapa,
    Contacto,
    Social,

}