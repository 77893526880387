import React, {useState, useEffect} from 'react';
import { ClientImages} from "../../../../api";
import "../../Prioridades/Description/PrioridadesDescription.scss"

const clientImagesController = new ClientImages();

export function BSCDescription() {

  const [prioridadesImagen, setPrioridadesImagen] = useState("");

  useEffect(() => {
    const cargarImagenDelBanner = async () => {
      try {
        // Solo carga la imagen específica para el banner
        const response = await clientImagesController.getClientImages({ code: "BSC_BSC" });
       
        const prioridadesImagenData = response.docs.find(doc => doc.code === "BSC_BSC");
   
        if (prioridadesImagenData && prioridadesImagenData.miniature) {
          setPrioridadesImagen(prioridadesImagenData.miniature);
        }
      } catch (error) {
        console.error("Error loading logo image:", error);
      }
    };

    cargarImagenDelBanner();
  }, []);


  if (!prioridadesImagen) {
    return null; // O algún placeholder mientras carga
  }

  return (
    <div className='prioridades-description'>

      <div className='prioridades-description__definition'>
        <div className='prioridades-description__definition__left'>
          <img  className='prioridades-description__definition__left__image' src={prioridadesImagen} alt='Proyecto Educativo Local'/>
        </div>

        <div className='prioridades-description__definition__right'>
          <h1>Balanced Scorecard</h1>
          <p>El Balanced Scorecard (BSC) es una herramienta estratégica que permite a la Gerencia Regional de Educación medir y evaluar el desempeño organizacional desde perspectivas clave. A través de indicadores que abarcan aspectos financieros, usuarios, procesos internos y aprendizaje y crecimiento, el BSC facilita la alineación de objetivos estratégicos con actividades diarias.</p>
        </div>
        
        
      </div>

    </div>
  )
}
