import React from 'react';
import {BannerPer, DescriptionPer, PerObjetivos} from "../../../components/Web"


export function Per() {
  return (
    <div>
      <BannerPer />
      <DescriptionPer />
      <PerObjetivos />
    </ div>
  )
}
