import React, { useState, useEffect } from "react";
import { Loader, Pagination } from "semantic-ui-react";
import { size, map } from "lodash";
import { PowerBi } from "../../../../api";
import { PowerBiItem } from "../PowerBiItem";
import "./ListPowerBi.scss";

const powerbiController = new PowerBi();

export function ListPowerBi(props) {
  const { reload, onReload } = props;
  const [powerbis, setPowerbis] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await powerbiController.getPowerBi({ page });
        setPowerbis(response.docs);
        setPagination({
          limit: response.limit,
          page: response.page,
          totalPages: response.totalPages, // Asegúrate de que esto coincida con la prop esperada por Pagination
          total: response.totalDocs, // Cambiado de response.total a response.totalDocs basado en tu log
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [page, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  if (!powerbis) return <Loader active inline="centered" />;
  if (size(powerbis) === 0) return "No hay ningun reporte";

  return (
    <div className="list-powerbis">
      {map(powerbis, (powerbi) => (
        <PowerBiItem key={powerbi._id} powerbi={powerbi} onReload={onReload} />
      ))}

      <div className="list-powerbis__pagination">
        <Pagination
          totalPages={pagination.totalPages} // Esto debe coincidir con lo que esperas de tu estado
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
          />
      </div>
    </div>
  );
}