import React, {useState, useEffect}  from 'react';
import {Menu} from "../../../../api"
import { map} from "lodash";
import "./Mapa.scss";

const menuController = new Menu();

export function Mapa() {

  const [menu, setMenu] = useState(null);
  

  useEffect ( () => {
    (async () => {
      try {
        const response = await menuController.getMenu(true);
        setMenu(response);
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  return (


    <div className='mapa-footer'>

      <div className='mapa-footer__title'>
        <h3>MAPA DEL SITIO</h3>
      </div>
      <div className='mapa-footer__links'>

        {map(menu, (item) => (
            <a key={item._id} href={item.path}>
              {item.title}
            </a>
          ))}


      </div>
          

    </div> 
  )
}
