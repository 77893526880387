import React from 'react';
import {Menu, Icon} from "semantic-ui-react";
import {Link, useLocation} from "react-router-dom";
import {useAuth} from "../../../../hooks"
import "./AdminMenu.scss";

export function AdminMenu() {

  const { pathname } = useLocation();
  const {
    user: { role },
  } = useAuth();
  const isAdmin = role === "admin";

  const isCurrentPath = (path) => {
    if (path === pathname) return true;
    return false;
  };

  return (

    <Menu fluid vertical icon text className="admin-menu">
    {isAdmin && (
      <>
        <Menu.Item
          as={Link}
          to="/admin/users"
          active={isCurrentPath("/admin/users")}
        >
          <Icon name="user outline" />
          Usuario
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/admin/menu"
          active={isCurrentPath("/admin/menu")}
        >
          <Icon name="bars" />
          Menu
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/admin/image"
          active={isCurrentPath("/admin/image")}
        >
          <Icon name="images outline" />
          Imágenes
        </Menu.Item>


        <Menu.Item
          as={Link}
          to="/admin/powerbi"
          active={isCurrentPath("/admin/powerbi")}
        >
          <Icon name="database" />
          Power Bi
        </Menu.Item>


        <Menu.Item
          as={Link}
          to="/admin/repositorio"
          active={isCurrentPath("/admin/repositorio")}
        >
          <Icon name="book" />
          Repositorio
        </Menu.Item>
      </>
    )}

  </Menu>


  )
}
