
import React from 'react';
import {RepositorioBanner, RepositorioDescription } from "../../../components/Web/Repositorio"

export function Repositorio() {
  return (
    <div>
        <RepositorioBanner />
        <RepositorioDescription />
    </div>
  )
}
