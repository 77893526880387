import React, { useState } from "react";
import { Image, Button, Icon, Confirm } from "semantic-ui-react";
import {image} from "../../../../assets"
import { BasicModal } from "../../../Shared";
import {  Repositorio } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { RepositorioForm } from "../RepositorioForm";
import "./RepositorioItem.scss";

const repositorioController = new Repositorio();

export function RepositorioItem(props) {
  const { repositorio, onReload } = props;
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const { accessToken } = useAuth();

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openUpdateCourse = () => {
    setTitleModal(`Actualizar ${repositorio.title}`);
    onOpenCloseModal();
  };

  const onDelete = async () => {
    try {
      await repositorioController.deleteEntry(accessToken, repositorio._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  

  return (
    <>
      <div className="repositorio-item">
        <div className="repositorio-item__info">
        <Image src={repositorio.coverImage || image.noAvatar } />
          
          <div>
            <p>{repositorio.title}</p>
  
          </div>
        </div>
        

        <div>
          <Button icon as="a" href={repositorio.pdf} target="_blank">
            <Icon name="eye" />
          </Button>
          <Button icon primary onClick={openUpdateCourse}>
            <Icon name="pencil" />
          </Button>
          <Button icon color="red" onClick={onOpenCloseConfirm}>
            <Icon name="trash" />
          </Button>
        </div>
      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <RepositorioForm
          onClose={onOpenCloseModal}
          onReload={onReload}
          repositorio={repositorio}
        />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={onDelete}
        content={`Eliminar el ${repositorio.title}`}
        size="mini"
      />
    </>
  );
}
