import React from 'react'
import { TbWorld } from "react-icons/tb";
import { FaTiktok, FaFacebook } from "react-icons/fa";
import "./social.scss"

export function Social() {
  return (
    <div className='social-footer'>
      <div className='social-footer__title'> 
        <h3>REDES SOCIALES</h3>
      </div>
      <div className='social-footer__links'>
        <a href="http://www.grell.gob.pe/" target='_blank' className='social-footer__link' rel='noreferrer'>
          <TbWorld />
        </a>
        
        <a href="https://www.facebook.com/GRELLIBERTAD" target='_blank' className='social-footer__link' rel='noreferrer'>
          <FaFacebook />
        </a>

        <a href="https://www.tiktok.com/@grell.pe" target='_blank' className='social-footer__link' rel='noreferrer'>
          <FaTiktok />
        </a>
      </div>
    </div>
  )
}
