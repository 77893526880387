import React, { useState } from 'react';
import {Tab, Button} from "semantic-ui-react";
import {BasicModal} from "../../../components/Shared"
import "./Image.scss";
import { ClientImagesForm, ListClientImages} from "../../../components/Admin/ClientImages"


export function Image() {

  const [showModal, setShowModal] = useState(false);
  const [reload, setReload ] = useState(false);

  const onOpenCloseModal = () => setShowModal ((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);

  const panes = [
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListClientImages reload={reload} onReload={onReload}/>
        </Tab.Pane>
      ),
    },
  ]

  return (
    <>
      <div className='clientimage-page'>
        <div className='clientimage-page__add'>
          <Button primary onClick={onOpenCloseModal}>
            Nueva imagen
          </Button>
        </div>

        <Tab menu={{secondary: true }} panes={panes} />

        

      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title="Crear imagen">
        <ClientImagesForm onClose={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  )
}


