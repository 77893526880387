import React from 'react';
import {Banner, Info, Socios} from "../../../components/Web/Home"

export  function Home() {
  return (
    <div>
    <Banner />
    <Info />
    <Socios />
    </div>
  )
}
