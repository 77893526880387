import React, { useCallback } from "react";
import { Form, Image } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { PowerBi } from "../../../../api";
import { useAuth } from "../../../../hooks";
import {image} from "../../../../assets"
import { initialValues, validationSchema } from "./PowerBiForm.form";
import "./PowerBiForm.scss";

const powerbiController = new PowerBi();

export function PowerBiForm(props) {
  const { onClose, onReload, powerbi } = props;
  const { accessToken } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(powerbi),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {

        const formData = new FormData();
        Object.keys(formValue).forEach(key => {
          if (key !== "file") {
          formData.append(key, formValue[key]);
          }
        });

        if (formValue.file) {
          formData.append("miniature", formValue.file); // Asegurarse de que este campo coincide con el esperado en el backend
          }

        if (!powerbi) {
          await powerbiController.createPowerBi(accessToken, formData);
        } else {
          await powerbiController.updatePowerBi(
            accessToken,
            powerbi._id,
            formData
          );
        }

        onReload();
        onClose();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    formik.setFieldValue("miniature", URL.createObjectURL(file));
    formik.setFieldValue("file", file);
  }, [formik]); // Agrega este arreglo de dependencias vacío
  

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop,
  });

  const getMiniature = () => {
    if (formik.values.file) {
      return formik.values.miniature;
    } else if (formik.values.miniature) {
      return powerbi.miniature;
      
    }
    return image.noAvatar;
  };

  return (
    <Form className="powerbi-form" onSubmit={formik.handleSubmit}>
      <div className="powerbi-form__miniature" {...getRootProps()}>
        <input {...getInputProps()} />
        
        {getMiniature() ? (
          <Image size="small" src={getMiniature()} />
        ) : (
          <div>
            <span>Arrastra tu miniatura</span>
          </div>
        )}
      </div>

      <Form.Group widths= "equal">
        <Form.Input
          name="title"
          placeholder="Nombre del powerbi"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title}
        />
        <Form.Input
          name="code"
          placeholder="código"
          onChange={formik.handleChange}
          value={formik.values.code}
          error={formik.errors.code}
        />

      </Form.Group>

      <Form.Input
        name="url"
        placeholder="Link del powerbi"
        onChange={formik.handleChange}
        value={formik.values.url}
        error={formik.errors.url}
      />




      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {!powerbi ? "Crear powerbi" : "Actualizar powerbi"}
      </Form.Button>
    </Form>
  );
}